import React from 'react'
import { graphql, Link } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

class eula extends React.Component {
    render() {
        const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
        return (
            <Layout>
                <HeaderSEO pageSEO={pageSEO} />
                {/* <!-- ========== End User License Agreement ========== --> */}
                <section className='mt-5 py-5 px-2'>
                    <div className='container-1'>
                        <div className='row pt-5'>
                            {/* Purpose of document */}
                            <div className='col-md-12 pt-5'>
                                <div className='text-center'>
                                    <p className='f-26 fw-600 pb-5'>
                                        End User License Agreement for Lenses.io
                                        <br />
                                        Community Edition
                                    </p>
                                </div>
                                <br />

                                {/* Order */}
                                <p>
                                    THIS END USER LICENSE AGREEMENT (THE &quot;AGREEMENT&quot;) IS
                                    BETWEEN YOU (&quot;YOU&quot; OR &quot;YOUR&quot;) AND LENSES.IO
                                    LTD. (COLLECTIVELY, &quot;WE,&quot; &quot;US&quot;,
                                    &quot;OUR&quot; OR &quot;LENSES&quot;). BY (1) CHECKING A BOX
                                    INDICATING YOUR ACCEPTANCE, OR (2) INSTALLING OR USING THE
                                    SOFTWARE IN WHOLE OR IN PART, YOU AGREE TO BE BOUND BY THIS
                                    AGREEMENT AND THE CELONIS PRIVACY NOTICE. WE MAY IN OUR
                                    DISCRETION CHANGE THESE TERMS, THE PRIVACY NOTICE OR ANY ASPECT
                                    OF THE SOFTWARE, WITHOUT NOTICE TO YOU. IF YOU DO NOT AGREE TO
                                    ANY CHANGES, YOU MUST CEASE USING THE SOFTWARE AND DESTROY ALL
                                    COPIES THEREOF.
                                </p>
                                <ol type='1' className='numbered'>
                                    <li>
                                        <strong>Definitions.</strong> All definitions not defined in
                                        the main body of this Agreement are specified in Annex A.
                                    </li>
                                    <li>
                                        <strong>Agreement.</strong> This Agreement governs Your
                                        access to and use of the Community Edition of the Lenses.io
                                        developer experience for building & operating real-time
                                        applications on Apache Kafka (the &quot;Software&quot;) and
                                        is effective as of the date of the initial Delivery of the
                                        Software to You.
                                    </li>
                                    <li>
                                        <strong>Software License and Ownership.</strong>
                                        <ol type='1' className='numbered'>
                                            <li>
                                                Subject to the terms and conditions of this
                                                Agreement, We hereby grant You a non-transferable,
                                                non-sublicensable, non-exclusive, worldwide license
                                                to install and use the Software: (i) in accordance
                                                with the limitations set forth in the Documentation
                                                and (ii) on the Supported Technologies. You are
                                                responsible for procuring the rights to use the
                                                Supported Technologies.
                                            </li>
                                            <li>
                                                You are entitled to install the Software in two
                                                environments only. You shall notify Us immediately
                                                if You become aware of any unauthorized use of the
                                                Software by any person.
                                            </li>
                                            <li>
                                                As between You and Us, We are and remain exclusive
                                                owners of all rights in and to: (a) the Software,
                                                including but not limited to any modifications
                                                thereto or derivative works thereof; (b) all ideas,
                                                inventions, discoveries, improvements, information,
                                                creative works and any other works discovered,
                                                prepared or developed in the course of or resulting
                                                from the provision of the Software to You; and (c)
                                                any and all Proprietary Rights embodied in the
                                                foregoing.
                                            </li>
                                            <li>
                                                To the extent You provide feedback regarding Our
                                                Software, products, business or development plans,
                                                or technology roadmaps, including, without
                                                limitation, comments or suggestions regarding the
                                                possible creation, modification, correction,
                                                improvement or enhancement of the Software or other
                                                products (collectively &quot;Feedback&quot;), You
                                                hereby grant Us a worldwide, perpetual, irrevocable,
                                                royalty-free license to use and incorporate such
                                                Feedback for any legitimate business purpose without
                                                restriction. We are under no obligation to use the
                                                Feedback.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>Your Obligations.</strong>
                                        <ol type='1' className='numbered'>
                                            <li>
                                                You shall: (i) comply with all applicable laws in
                                                connection with Your performance under this
                                                Agreement, including without limitation privacy,
                                                export control, and sanction laws; (ii) use
                                                commercially reasonable efforts to prevent any
                                                unauthorized access to or use of the Software and
                                                promptly notify Us in the event of any such
                                                unauthorized access or use; (iii) have sole
                                                responsibility for the accuracy, quality, and
                                                legality of Customer Materials; (iv) be solely
                                                liable for making appropriate backups of Customer
                                                Materials; and (v) obtain any legally-necessary
                                                consents and/or provide required privacy notices to
                                                any party whose personal data you input into the
                                                Service or otherwise provide Us.
                                            </li>
                                            <li>
                                                In connection with this Agreement, You shall not
                                                (and shall not allow any third party to): (i)
                                                publicly disseminate performance information or
                                                analysis (including, without limitation, benchmarks)
                                                from any source relating to the Software; (ii)
                                                utilize any equipment, device, software, or other
                                                means designed to circumvent or remove any form of
                                                product access keys or copy protection used by Us in
                                                connection with the Software; (iii) decompile,
                                                disassemble, or otherwise reverse engineer any part
                                                of the Software, or attempt to reconstruct or
                                                discover any source code, underlying ideas,
                                                algorithms, file formats or programming interfaces
                                                of the Software by any means whatsoever; (iv) sell,
                                                assign, sublicense, rent, lease, loan, distribute,
                                                share, timeshare, grant a security interest in, use
                                                for service bureau purposes, or otherwise transfer
                                                all or any portion of the Software; (v) use or
                                                reference the Software to develop a product that is
                                                functionally similar to the Software; (vi) remove or
                                                alter any trademark, logo, copyright or other
                                                proprietary notices associated with the Software;
                                                (vii) use or disclose the Software in any manner not
                                                expressly authorized by this Agreement; or (viii)
                                                modify, copy, translate or create derivative works
                                                based on the Software or any part, feature, function
                                                or user interface thereof.
                                            </li>
                                            <li>
                                                You shall defend, indemnify and hold Us harmless
                                                from and against any and all claims and/or Losses
                                                arising out of or in connection with Your breach of
                                                this Section 4.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>Confidentiality.</strong> Each party retains all
                                        Proprietary Rights in its Confidential Information. Except
                                        as expressly authorized herein, each Party will hold in
                                        confidence and not disclose any Confidential Information of
                                        the other party except: (i) to its Representatives who have
                                        a need to know such information for purposes of performing
                                        under this Agreement and who agree in writing to keep the
                                        information confidential on terms no less restrictive than
                                        those contained in this Agreement; (ii) as permitted in
                                        writing by the other party; (iii) to the extent required
                                        under applicable law or regulation after giving the
                                        disclosing party (if legally allowed) an opportunity to seek
                                        legal protection or otherwise prevent or limit disclosure of
                                        the Confidential Information; or (iv) to the extent such
                                        Confidential Information becomes public through no fault of
                                        the receiving party. The parties will ensure that their
                                        Representatives comply with this Agreement and will be
                                        responsible for any unauthorized use or disclosure of
                                        Confidential Information by such Representatives.
                                    </li>
                                    <li>
                                        <strong>WAIVER OF WARRANTIES AND SUPPORT.</strong> TO THE
                                        FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AND
                                        NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
                                        THE SOFTWARE IS PROVIDED &quot;AS-IS&quot; WITHOUT SUPPORT
                                        OR WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR
                                        OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF
                                        TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                                        NONINFRINGEMENT.
                                    </li>
                                    <li>
                                        <strong>LIMITATION OF LIABILITY.</strong> WE SHALL HAVE NO
                                        LIABILITY OF ANY KIND WITH RESPECT TO THE SOFTWARE,
                                        INCLUDING WITHOUT LIMITATION FOR ANY LOSS OF USE, LOST DATA,
                                        FAILURE OF SECURITY MECHANISMS, INTERRUPTION OF BUSINESS, OR
                                        ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
                                        OF ANY KIND (INCLUDING LOST PROFITS OR COSTS OF COVER),
                                        REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT
                                        (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EVEN
                                        IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE.
                                        WHERE SUCH EXCLUSION OF LIABILITY IS PROHIBITED UNDER
                                        APPLICABLE LAW, OUR TOTAL AGGREGATE LIABILITY SHALL NOT
                                        EXCEED ONE HUNDRED U.S. DOLLARS ($100), WHICH THE PARTIES
                                        AGREE IS A FAIR AND REASONABLE AMOUNT.
                                    </li>
                                    <li>
                                        <strong>Termination and Effect of Termination.</strong> We
                                        may terminate this Agreement and Your access to the Software
                                        at any time and for any reason without penalty with or
                                        without notice to You. Upon termination, You shall
                                        immediately cease any and all use of the Software, destroy
                                        (or at Our request return) any of Our Confidential
                                        Information related hereto, and certify such destruction in
                                        writing.
                                    </li>
                                    <li>
                                        <strong>Export.</strong> The Software (and derivatives
                                        thereof) may be subject to export laws and regulations of
                                        the United States and other jurisdictions (&quot;Export
                                        Laws&quot;). You will not and will not allow any third party
                                        to: (i) export, re-export or transfer any part of the
                                        Software to countries, persons or entities prohibited by
                                        Export Laws or (ii) permit access to or use the Software in
                                        or from a U.S.-embargoed country or region. We may block,
                                        restrict, limit, revoke or suspend access to the Software by
                                        anyone that is subject to any applicable sanctions or
                                        embargoes.
                                    </li>
                                    <li>
                                        <strong>Governing Law.</strong> Excluding conflict of laws
                                        rules, this Agreement shall be governed by the laws of the
                                        state of New York and the parties submit to exclusive
                                        jurisdiction of the courts located in New York, New York.
                                        The United Nations Convention on Contracts for the
                                        International Sale of Goods and the Uniform Computer
                                        Information Transactions Act, as currently enacted by any
                                        jurisdiction or as may be codified or amended from time to
                                        time by any jurisdiction, do not apply to this Agreement.
                                    </li>
                                    <li>
                                        <strong>General Terms.</strong>
                                        <ol type='1' className='numbered'>
                                            <li>
                                                <strong>Assignment.</strong> You may not assign or
                                                otherwise transfer this Agreement (in whole or in
                                                part) without Our prior written consent. Any attempt
                                                by You to otherwise transfer or assign this
                                                Agreement will be null and void. We may assign or
                                                otherwise transfer this Agreement (in whole or in
                                                part) and/or the Proprietary Rights in and to the
                                                Software at any time in our sole discretion and
                                                without notice to You.
                                            </li>
                                            <li>
                                                <strong>Independent Contractors.</strong> The
                                                parties are independent contractors. The Agreement
                                                does not create a partnership, franchise, joint
                                                venture, agency, fiduciary, employment or any such
                                                similar relationship between You and Us.
                                            </li>
                                            <li>
                                                <strong>Third Party Beneficiaries.</strong> Nothing
                                                in this Agreement is intended to, nor shall create,
                                                any right enforceable by any third party not a party
                                                to this Agreement. The consent of a third party
                                                shall not be required for the amendment, variation
                                                or termination of this Agreement.
                                            </li>
                                            <li>
                                                <strong>Entire Agreement and Amendments.</strong>{' '}
                                                This Agreement, as may be updated from time-to-time
                                                and posted at{' '}
                                                <Link to='/legals/policy/'>
                                                    https://lenses.io/legals/policy/
                                                </Link>
                                                , constitutes the entire agreement between the
                                                parties regarding Your use of and access to the
                                                Software and supersedes all prior and
                                                contemporaneous agreements, proposals and
                                                representations, whether written or oral, concerning
                                                the subject matter hereof.
                                            </li>
                                            <li>
                                                <strong>Severability.</strong> If any court of
                                                competent jurisdiction finds any provision of this
                                                Agreement to be invalid, unenforceable or illegal,
                                                such provision shall be severed from this Agreement,
                                                but the other provisions of this Agreement shall
                                                remain in full force and effect.
                                            </li>
                                            <li>
                                                <strong>Waiver.</strong> No failure or delay by
                                                either party in exercising any right under this
                                                Agreement will constitute a waiver of that right.
                                            </li>
                                            <li>
                                                <strong>Surviving Provisions.</strong> The terms
                                                which by their nature are intended to survive
                                                termination or expiration of the Agreement shall
                                                survive any such termination and expiration.
                                            </li>
                                            <li>
                                                <strong>Language.</strong> Regardless of any
                                                language into which this Agreement may be
                                                translated, the official, controlling and governing
                                                version of this Agreement shall be exclusively the
                                                English language version.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <p className='text-center'>
                                    <strong>
                                        Annex A
                                        <br />
                                        Definitions
                                    </strong>
                                </p>
                                <ol type='1' className='numbered'>
                                    <li>
                                        <strong>&quot;Affiliate&quot;</strong>: any entity that
                                        directly or indirectly controls, is controlled by, or is
                                        under common control with Us, but only for so long as such
                                        control exists. As used here, &quot;Control,&quot; means
                                        direct or indirect ownership or control of more than 50% of
                                        the voting interests.
                                    </li>
                                    <li>
                                        <strong>&quot;Celonis Privacy Notice&quot;</strong>: the
                                        then-current privacy policy found at{' '}
                                        <a
                                            href='https://www.celonis.com/privacy-policy'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            https://www.celonis.com/privacy-policy
                                        </a>{' '}
                                        and incorporated herein by this reference.
                                    </li>
                                    <li>
                                        <strong>&quot;Confidential Information&quot;</strong>: any
                                        information disclosed to a party by the other party
                                        concerning the business and/or affairs of the other party,
                                        including but not limited to information relating to a
                                        party&apos;s operations, technical or commercial know-how,
                                        specifications, inventions, processes or initiatives, plans,
                                        product information, pricing information, know-how, designs,
                                        trade secrets, software, documents, data and information
                                        which, when provided by one party to the other: a) are
                                        clearly identified as &quot;Confidential&quot; or
                                        &quot;Proprietary&quot; or are marked with a similar legend;
                                        b) are identified as Confidential Information at the time of
                                        disclosure and confirmed as Confidential Information in
                                        writing within a reasonable period of time after disclosure;
                                        or c) a reasonable person would understand to be
                                        confidential or proprietary at the time of disclosure.
                                    </li>
                                    <li>
                                        <strong>&quot;Customer Materials&quot;</strong>: any
                                        materials, data, information, software, equipment or other
                                        resources owned by or licensed to You and made available to
                                        Us pursuant to facilitating Your use of the Software,
                                        including Your data.
                                    </li>
                                    <li>
                                        <strong>&quot;Delivery&quot;</strong>: the date Software is
                                        made available for download by You (&quot;Delivery&quot;).
                                    </li>
                                    <li>
                                        <strong>&quot;Documentation&quot;</strong>: the then-current
                                        product description of the Software, as made available by Us
                                        on the Celonis website found at{' '}
                                        <a
                                            href='https://www.celonis.com/terms-and-conditions/'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            https://www.celonis.com/terms-and-conditions/
                                        </a>{' '}
                                        and incorporated herein by this reference.
                                    </li>
                                    <li>
                                        <strong>&quot;Lenses Materials&quot;</strong>: any software,
                                        programs, tools, systems, data, Confidential Information or
                                        other materials made available by Us to You under this
                                        Agreement.
                                    </li>
                                    <li>
                                        <strong>&quot;Proprietary Rights&quot;</strong>: rights in
                                        patents, utility models, trademarks, service marks, trade
                                        names, other trade-identifying symbols and inventions,
                                        copyrights, design rights, database rights, rights in
                                        know-how, trade secrets and any other intellectual property
                                        rights, anywhere in the world, whether registered or
                                        unregistered, and including applications for the grant of
                                        any such rights.
                                    </li>
                                    <li>
                                        <strong>&quot;Representatives&quot;</strong>: of a party are
                                        its and its Affiliates’ employees, officers, directors,
                                        advisers, agents and subcontractors.
                                    </li>
                                    <li>
                                        <strong>&quot;Supported Technologies&quot;</strong>: the
                                        versions of Apache Kafka with which the Software must be
                                        used.
                                    </li>
                                    <li>
                                        <strong>&quot;User&quot;</strong>: those employees, agents
                                        and independent contractors of Yours (including third-party
                                        suppliers and/or contractors) who are authorized by You to
                                        access and use the Software in accordance with this
                                        Agreement, and to whom You have supplied a user
                                        identification and password (if applicable).
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
export default eula

export const pageQuery = graphql`
    query legalsPolicySeoAndLegalsPolicySeo {
        allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Legals_Policy" } } }) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                }
            }
        }
    }
`
